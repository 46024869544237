/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #fbc534;
  --ion-color-primary-rgb: 251,197,52;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #ddad2e;
  --ion-color-primary-tint: #fbcb48;

  --ion-color-secondary: #424242;
  --ion-color-secondary-rgb: 66,66,66;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #3a3a3a;
  --ion-color-secondary-tint: #555555;

  --ion-color-tertiary: #fff869;
  --ion-color-tertiary-rgb: 255,248,105;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #e0da5c;
  --ion-color-tertiary-tint: #fff978;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #ab1226 ;
  --ion-color-danger-rgb: 171,18,38;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #961021;
  --ion-color-danger-tint: #b32a3c;

  --ion-color-medium: #595959;   /* before it was: #92949c */
  --ion-color-medium-rgb: 89,89,89;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #4e4e4e;
  --ion-color-medium-tint: #6a6a6a;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /*
   * Custom colors https://ionicframework.com/docs/theming/colors#new-color-creator
   * -------------------------------------------
   */
  --ion-color-other: #c49500;
  --ion-color-other-rgb: 196,149,0;
  --ion-color-other-contrast: #000000;
  --ion-color-other-contrast-rgb: 0,0,0;
  --ion-color-other-shade: #ac8300;
  --ion-color-other-tint: #caa01a;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
}

  /*
   * Custom colors classes https://ionicframework.com/docs/theming/colors#new-color-creator
   * -------------------------------------------
   */
.ion-color-other {
  --ion-color-base: var(--ion-color-other);
  --ion-color-base-rgb: var(--ion-color-other-rgb);
  --ion-color-contrast: var(--ion-color-other-contrast);
  --ion-color-contrast-rgb: var(--ion-color-other-contrast-rgb);
  --ion-color-shade: var(--ion-color-other-shade);
  --ion-color-tint: var(--ion-color-other-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

  /*
   * End custom colors classes
   * -------------------------------------------
   */

  /*
   * Dark Colors
   * -------------------------------------------
   */

  body.dark {
    --ion-color-primary: #fbc534;
    --ion-color-primary-rgb: 251,197,52;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #ddad2e;
    --ion-color-primary-tint: #fbcb48;

    --ion-color-secondary: #ffffff;
    --ion-color-secondary-rgb: 255,255,255;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #e0e0e0;
    --ion-color-secondary-tint: #ffffff;

    --ion-color-tertiary: #fff869;
    --ion-color-tertiary-rgb: 255,248,105;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #e0da5c;
    --ion-color-tertiary-tint: #fff978;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    /*
     * Custom colors https://ionicframework.com/docs/theming/colors#new-color-creator
     * -------------------------------------------
     */
    --ion-color-other: #6a4700;
    --ion-color-other-rgb: 106,71,0;
    --ion-color-other-contrast: #ffffff;
    --ion-color-other-contrast-rgb: 255,255,255;
    --ion-color-other-shade: #5d3e00;
    --ion-color-other-tint: #79591a;

    --ion-color-white: #00000;
    --ion-color-white-rgb: 0,0,0;
    --ion-color-white-contrast: #ffffff;
    --ion-color-white-contrast-rgb: 255,255,255;
    --ion-color-white-shade: #000000;
    --ion-color-white-tint: #1a1a1a;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal.dark {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
