.secure-routing-page {
  .custom-tab-bar {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }

  .custom-tab {
    position: relative;
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;

    &__unread {
      position: absolute;
      right: 16px;
      top: 3px;
    }

    ion-label {
      font-size: 10px;
    }
    ion-icon {
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 30px;
    }
  }

  .custom-tab-inner {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}