.info-list-page {
  ion-list {
    background: transparent;
  }

  ion-item {
    --background: var(--ion-color-white);
    --border-width: 0 0 0.55px 0 !important;

    --detail-icon-color: var(--ion-color-primary);
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 30px;
  }

  ion-accordion-group {
    ion-accordion {
      div[slot="content"] {
        background: var(--ion-color-light)
      }

      .ion-accordion-toggle-icon {
        color: var(--ion-color-primary);
        font-size: 30px;
      }
    }
  }

  .info-page-entry-title {
    display: flex;
    align-items: baseline;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 8px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    ion-icon {
      flex-shrink: 0;
    }
  }
}
