.helper-list-page {
  ion-list {
    background: transparent;
  }

  ion-item {
    --background: var(--ion-color-white);
    --border-width: 0 !important;
    --border-radius: 8px;

    --detail-icon-color: var(--ion-color-primary);
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 30px;
  }
}
