.message-input {
  --background: var(--ion-color-dark-contrast);
  --padding-start: 12px;
  --padding-end: 12px;
  border-radius: 20px;
  max-height: 190px;
}

.chat-list {
  .chat-item {
    //margin-bottom: 8px;

    &.chat-item-sender {
      text-align: right;

      .chat-item-content {
        justify-content: flex-end;
      }

      .chat-body {
        background: var(--ion-color-primary);
        border-radius: 20px;
        border-color: transparent;
        white-space: pre-wrap;
      }
    }
  }

  .chat-timestamp {
    text-align: center;
    font-size: 13px;
    padding: 8px;
    color: var(--ion-color-medium);
    text-transform: uppercase;
  }

  .chat-item-status {
    font-size: 13px;
    color: var(--ion-color-medium);
    margin-right: 8px;
  }

  .chat-item-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;

    .chat-avatar {
      margin-right: 8px;
      align-self: flex-end;

      img {
        display: inline-block;
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  .chat-item-bubble {
    position: relative;
    max-width: 75%;

    .chat-body {
      display: inline-block;
      background: var(--ion-color-light);
      border-radius: 20px;
      padding: 12px 16px;
      white-space: normal;
      max-width: 100%;
      text-align: left;
    }

    &.bubble-image {
      font-size: 0;

      img {
        width: 100%;
        max-width: 480px;
        border-radius: 16px;
      }
    }
  }

  .chat-item-reaction {
    text-align: left;
    margin-left: 8px;
    font-size: 18px;
    align-self: center;
    flex-grow: 99;
  }

  .chat-item-event {
    padding: 0.25rem 1rem;
    margin: 0.25rem auto;
    text-align: center;
    color: var(--ion-color-secondary);
    overflow: hidden;
  }
}

// dark theme
.dark {
  .chat-list {
    .chat-item-sender {
      .chat-body {
        color: var(--ion-color-light);
      }
    }
  }
}
