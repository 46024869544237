.login-page-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #ffffff;
    background-image: radial-gradient(#b8b8b8 1px, transparent 1px),
    radial-gradient(#b8b8b8 1px, #ffffff 1px);
    background-size: 40px 40px;
    background-position: 0 0, 20px 20px;

    ion-img {
        max-height: 33vh;
        object-fit: fill;
    }
}

.login-page {
    ion-card-title {
        color: black !important;
        font-weight: 900 !important;
    }

    .content-box {
        margin-top: -10vh;
        flex: 1 1 auto;
        align-items: center;
        display: flex;
    }

    ion-button {
        min-width: 200px;
    }
}
