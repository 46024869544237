/* rewrite alert buttons styles */
button.alert-button.alert-button-role-cancel {
    color: var(--ion-color-other);
}
button.alert-button.alert-button-role-confirm {
    color: var(--ion-color-other);
}

.dark button.alert-button.alert-button-role-cancel {
    color: var(--ion-color-primary);
}
.dark button.alert-button.alert-button-role-confirm {
    color: var(--ion-color-primary);
}

/* rewrite alert buttons styles for special css class */
button.alert-button.alert-button-confirm {
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
}
button.alert-button.alert-button-cancel {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
}
.dark button.alert-button.alert-button-cancel {
    color: var(--ion-color-primary-contrast);
}

ion-tab-button {
    --color-selected: var(--ion-color-other);
}

/* selected tab icon color */
ion-tab-button.tab-selected ion-icon {
    color: var(--ion-color-primary);
}

/* size for all pages headers */
ion-toolbar h1 {
    font-size: 1.1rem;
    font-weight: bold;
}
